import React, { useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import PageContent from "../../components/PageContent";
import PageBoundary from "../../components/PageBoundary";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  LegalDocumentAttributes,
} from "../../types/cms";
import ReactMarkdown from "react-markdown";
import { useAuthContext } from "../../contexts/authContext";
import useNorthstar from "../../hooks/northstarHook";
import LoadingStatus from "../../components/LoadingStatus";
import axios, { isAxiosError } from "axios";
import { navigate } from "gatsby";

const TermsOfUsePage = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const { data, isLoading, error } = useNorthstar<
    CMSAPIDataCollection<CMSAPIDataItem<LegalDocumentAttributes>>
  >(
    `/api/legal-documents`,
    accessToken,
    {
      method: "get",
    },
    {
      filters: {
        document_id: {
          $eq: "disclaimers",
        },
      },
    },
  );

  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) clearIdentity();
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  const handleAccept = async () => {
    if (!accessToken) return;
    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/api/tou/record-acceptance`,
        {},
        {
          headers: {
            "northstar-identity": "okta",
            "northstar-authorization": `Bearer ${accessToken}`,
          },
        },
      );

      // Check response if necessary
      if (response.status === 200) {
        // Navigate to the next page upon successful acceptance
        navigate("/");
      } else {
        // Handle non-successful response
        console.error("Error recording acceptance:", response);
      }
    } catch (err) {
      if (!isAxiosError) throw err;
      return null;
    }
  };

  const handleReject = () => {
    navigate("/logout");
    if (clearIdentity) {
      clearIdentity();
    }
  };

  const seoData = {
    title: `BAM Applied Insights | Disclaimers & BAM User Agreement`,
    description: "BAM Applied Insights Disclaimers & BAM User Agreement",
  };

  let documentTemplate = <LoadingStatus loadingType="legal_document_loading" />;
  if (error) {
    documentTemplate = <LoadingStatus loadingType="legal_document_error" />;
  } else {
    if (isLoading) {
      documentTemplate = <LoadingStatus loadingType="legal_document_loading" />;
    } else if (!data?.data.length) {
      documentTemplate = <LoadingStatus loadingType="legal_document_loading" />;
    } else {
      const document = data.data[0].attributes;
      documentTemplate = (
        <div
          className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-4 bg-white border-gray-200/90 border-t-bam-alternative-blue shadow-sm"
        >
          <div className="my-3 pt-3 pb-2">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
              {document?.title}
            </h1>
          </div>
          <div className="mt-4 mb-6">
            <div className="prose prose-a:text-bam-orange w-full max-w-none">
              <div className="leading-relaxed">
                <ReactMarkdown>{document?.text}</ReactMarkdown>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="flex-1"></div>
            <div>
              <button
                type="button"
                className="inline-flex items-center my-1 px-4 py-2 border border-bam-blue text-bam-blue font-medium
                  hover:bg-bam-blue hover:text-white"
                onClick={() => handleAccept()}
              >
                Accept
              </button>
              {/* <button
                type="button"
                className="inline-flex items-center ml-2 my-1 px-4 py-2 bg-white text-gray-400 font-medium text-sm
                  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={() => handleReject()}
              >
                Reject
              </button> */}
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <PageLayout seo={seoData} showNav={false} showFooterDocs={false}>
      <PageContent>
        <PageBoundary>{documentTemplate}</PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default TermsOfUsePage;
